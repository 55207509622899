import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Input, InputNumber, DatePicker, Radio, Button} from 'antd';

import {INITIAL_FIELDS} from '../../../content/activity';
import * as AppContext from '../../AppContext';
import TimePicker from '../../Components/TimePicker';
import {ImageEditField} from './ActivityImageField';

function ActivityDetailForm(props) {
  const {onRefresh, onCancel} = props;
  const [values, setValues] = React.useState({
    ...INITIAL_FIELDS,
    ...props.record,
  });
  const app = React.useContext(AppContext.Context);

  const getInputProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({
          ...values,
          [field]: e.target.value,
        });
      },
    };
  };

  const getDatePickerProps = (field) => {
    return {
      value: !values[field] ? values[field] : moment(values[field]),
      onChange: (value) => {
        if (value === null) {
          setValues({...values, [field]: ''});
        } else {
          setValues({...values, [field]: value});
        }
      },
    };
  };

  const getDurationPickerProps = (field, index, type) => {
    if (type === 'date') {
      const currentDate = values[field][index][type];
      return {
        value: !currentDate ? currentDate : moment(currentDate),
        onChange: (value) => {
          const nextValue = values[field];
          if (value === null) {
            nextValue[index][type] = '';
          } else {
            nextValue[index][type] = moment(value).format('YYYY-MM-DD');
          }
          setValues({...values, [field]: nextValue});
        },
      };
    }

    if (type === 'time-start') {
      return {
        value: values[field][index]['time'][0],
        onChange: (value) => {
          const nextValue = [...values[field]];
          nextValue[index]['time'][0] = value;
          setValues({...values, [field]: nextValue});
        },
      };
    }

    if (type === 'time-end') {
      return {
        value: values[field][index]['time'][1],
        onChange: (value) => {
          const nextValue = [...values[field]];
          nextValue[index]['time'][1] = value;
          setValues({...values, [field]: nextValue});
        },
      };
    }
  };

  const getInputNumberProps = (field) => {
    return {
      value: values[field],
      onChange: (value) => {
        setValues({...values, [field]: value});
      },
    };
  };

  const getRadioGroupProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({...values, [field]: e.target.value});
      },
    };
  };

  const onAddDurationRecord = (field) => {
    const record = {date: '', time: []};
    const nextValue = values[field];
    nextValue.push(record);
    setValues({...values, [field]: nextValue});
  };

  const onRemoveDurationRecord = (field) => {
    const nextValue = values[field];
    if (nextValue.length > 1) {
      nextValue.pop();
      setValues({...values, [field]: nextValue});
    }
  };

  async function onUpdateRecord(values) {
    if (!values.name) {
      alert('Missing required field');
      return;
    }

    app.actions.setLoading(true);
    try {
      if (!values.id) {
        let resp = await app.actions.staffCreateActivity(values);
        setValues(resp);
        onRefresh(resp.id);
      } else {
        setValues(
          await app.actions.staffUpdateActivityDetailById(values.id, values),
        );
        onRefresh(values.id);
      }
    } catch (ex) {
      alert('API Fail:\n' + JSON.stringify(ex, null, 2));
    }
    app.actions.setLoading(false);
  }

  return (
    <Wrapper>
      <section>
        <h3>{values.name ? values.name : '新增活動'}</h3>

        <div className="field">
          <label>活動名稱</label>
          <Input {...getInputProps('name')} />
        </div>

        <div className="field">
          <label>活動狀態</label>
          <Radio.Group buttonStyle="soild" {...getRadioGroupProps('status')}>
            <Radio.Button value="draft">草稿</Radio.Button>
            <Radio.Button value="publish">活動宣傳</Radio.Button>
            <Radio.Button value="open">開放報名</Radio.Button>
            <Radio.Button value="end">報名截止</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>活動海報</label>
          <ImageEditField
            fieldname="image"
            record={values}
            onUpdateRecord={onUpdateRecord}
          />
        </div>

        <div className="field">
          <label>活動時間</label>
          <DatePicker
            placeholder="開始日期"
            {...getDatePickerProps('date_start')}
          />
          <div style={{width: 25, textAlign: 'center'}}>-</div>
          <DatePicker
            placeholder="結束日期"
            {...getDatePickerProps('date_end')}
          />
        </div>

        <div className="field">
          <label>活動地點</label>
          <Input {...getInputProps('address_name')} />
        </div>

        <div className="field">
          <label>活動地址</label>
          <Input {...getInputProps('address')} />
        </div>

        <div className="field">
          <label>活動簡述</label>
          <Input.TextArea
            {...getInputProps('description')}
            style={{minHeight: 150}}
          />
        </div>

        <div className="field">
          <label>活動內文</label>
          <Input.TextArea
            {...getInputProps('content')}
            style={{minHeight: 280}}
          />
        </div>

        <div className="field direction">
          <label>上班時段</label>
          <div className="duration-wrapper">
            {values.duration.map((record, index) => {
              return (
                <div className="duration-container">
                  <DatePicker
                    {...getDurationPickerProps('duration', index, 'date')}
                    style={{marginRight: 10}}
                  />
                  <TimePicker
                    {...getDurationPickerProps('duration', index, 'time-start')}
                    style={{marginRight: 10}}
                  />
                  <TimePicker
                    {...getDurationPickerProps('duration', index, 'time-end')}
                    style={{marginRight: 10}}
                  />
                  {values.duration.length - 1 === index && (
                    <div className="buttons-wrapper">
                      <Button
                        type="primary"
                        onClick={() => onAddDurationRecord('duration')}
                        style={{marginRight: 10}}>
                        新增時段
                      </Button>
                      <Button
                        onClick={() => onRemoveDurationRecord('duration')}>
                        刪除時段
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="field">
          <label>人數上限</label>
          <InputNumber
            {...getInputNumberProps('limit')}
            min={0}
            style={{marginRight: 10}}
          />
          人
        </div>
      </section>

      <div className="line" />

      <section
        style={{maxWidth: 600, display: 'flex', justifyContent: 'flex-end'}}>
        <div className="action-bar">
          <Button onClick={onCancel}>取消</Button>
          <Button type="primary" onClick={() => onUpdateRecord(values)}>
            {values.id ? '儲存' : '新增'}
          </Button>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > section {
    & > h3 {
      margin-bottom: 30px;
      font-size: 24px;
      color: #4a4a4a;
      line-height: 22px;
    }

    & > .field {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      & > label {
        width: 80px;
      }

      & > input {
        flex: 1;
        max-width: 520px;
      }

      & > textarea {
        max-width: 520px;
        resize: none;
      }

      & > .duration-wrapper {
        & > .duration-container {
          margin-bottom: 10px;
          display: flex;
          & > .buttons-wrapper {
            display: flex;
          }
        }
      }
    }

    & > .field:last-child {
      margin-bottom: 50px;
    }

    & > .action-bar {
      padding-top: 20px;
      padding-bottom: 50px;
      & > button {
        margin-right: 15px;
      }

      & button:last-child {
        margin-right: 0px;
      }
    }
  }

  & > .line {
    margin-bottom: 20px;
    max-width: 600px;
    width: 100%;
    height: 1px;
    background-color: #cccccc;
  }
`;

export default ActivityDetailForm;
