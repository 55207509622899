import React from 'react';
import {navigate} from 'gatsby';
import styled from 'styled-components';
import {Button, Modal} from 'antd';
import moment from 'moment';

import * as AppContext from '../AppContext';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  BlockOutlined,
  HighlightOutlined,
} from '../Widgets/Icon';
import SignupForm from './Activity.SignupForm';

function DisplayDetail(props) {
  const app = React.useContext(AppContext.Context);
  const {record, showButton = true} = props;
  const [openSignupModal, setOpenSignupModal] = React.useState(false);

  if (!record || !record.id) {
    return <Wrapper>待創建</Wrapper>;
  }

  return (
    <Wrapper>
      <div className="image-container">
        <img alt="activity" src={record.image} />
      </div>
      <div className="container">
        <h3>{record.name}</h3>
        <section>
          <div>
            <CalendarOutlined color="#4A4A4A" />
            <label>
              活動時間：{moment(record.date_start).format('YYYY/MM/DD')} ~{' '}
              {moment(record.date_end).format('YYYY/MM/DD')}
            </label>
          </div>

          <div>
            <EnvironmentOutlined color="#4A4A4A" />
            <label>活動地點：{record.address_name}</label>
          </div>

          <div className="field">
            <BlockOutlined color="#4A4A4A" />
            <label>活動地址：{record.address}</label>
          </div>

          <div className="field">
            <HighlightOutlined color="#4A4A4A" />
            <label>
              活動狀態：
              {(() => {
                switch (record.status) {
                  case 'draft':
                    return '草稿';
                  case 'publish':
                    return '活動宣傳';
                  case 'open':
                    return '開放報名';
                  case 'end':
                    return '報名截止';
                  default:
                    return record.status;
                }
              })()}
            </label>
          </div>
        </section>

        <div>
          <p>{record.description}</p>
          <p>{record.content}</p>
          {showButton &&
            (() => {
              switch (record.status) {
                case 'open':
                  return (
                    <Button
                      type="primary"
                      style={{width: 200, height: 40, cursor: 'pointer'}}
                      onClick={() => {
                        if (app.state.member || app.state.staff) {
                          setOpenSignupModal(true);
                        } else {
                          //user not login
                          navigate('/login');
                        }
                      }}>
                      我要報名
                    </Button>
                  );
                case 'end':
                  return (
                    <Button
                      type="disabled"
                      style={{
                        width: 200,
                        height: 40,
                        cursor: 'not-allowed',
                      }}>
                      報名截止
                    </Button>
                  );
                default:
                  return;
              }
            })()}
        </div>
      </div>

      <Modal
        title={null}
        footer={null}
        bodyStyle={{padding: 0}}
        width={500}
        visible={openSignupModal}
        onOk={() => {
          setOpenSignupModal(false);
        }}
        onCancel={() => {
          setOpenSignupModal(false);
        }}>
        <SignupForm
          record={record}
          onCancel={() => {
            setOpenSignupModal(false);
          }}
        />
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  & > .image-container {
    margin-right: 47px;
    max-width: 411px;
    width: 100%;
    height: auto;

    & > img {
      width: 100%;
      height: auto;
    }
  }

  & > .container {
    flex: 1;

    & > h3 {
      font-size: 20px;
      color: #4a4a4a;
      margin-bottom: 20px;
    }

    & > section {
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #eaeaea;
      padding: 10px;

      & > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & > label {
          margin-left: 16px;
          color: #4a4a4a;
        }
      }
    }

    & > div {
      margin: 20px 0px;
      padding: 15px 10px;

      & > p {
        white-space: pre-wrap;
        color: #4a4a4a;
        text-align: justify;
        line-height: 30px;
      }
    }
  }
`;

export default DisplayDetail;
