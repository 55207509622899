import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import moment from 'moment';

import * as AppContext from '../AppContext';

function SignupDetail(props) {
  const app = React.useContext(AppContext.Context);
  const {record, onCancel} = props;
  const [selectedDuration, setSelectedDuration] = React.useState([]);
  const [message, setMessage] = React.useState({
    error: false,
    text: '',
  });

  const onSelectDurationRecord = (record) => {
    let nextValue = [...selectedDuration];
    if (getSelectedDurationRecordIndex(record) >= 0) {
      nextValue.splice(getSelectedDurationRecordIndex, 1);
    } else {
      nextValue.push(record);
    }

    setSelectedDuration(nextValue);
  };

  const getSelectedDurationRecordIndex = (record) => {
    let hitRecordIndex = selectedDuration.findIndex((item) => {
      let hitDate = item.date === record.date;
      let hitTime =
        item.time[0] === record.time[0] && item.time[1] === record.time[1];

      if (hitDate && hitTime) {
        return true;
      } else {
        return false;
      }
    });

    return hitRecordIndex;
  };

  const onConfirm = async () => {
    app.actions.setLoading(true);
    try {
      await app.actions.memberCreateParticipant({
        id: record.id,
        duration: selectedDuration,
      });
      setMessage({error: false, text: '報名成功！'});
    } catch (err) {
      const json = await err.json();

      if (json.error === 'applied_already_exist') {
        setMessage({error: true, text: '上述時段已報名！'});
      }
    }
    app.actions.setLoading(false);
  };

  return (
    <Wrapper>
      <h3>選擇上班時段</h3>
      <section>
        {record.duration
          .sort(
            (a, b) =>
              new Date(`${a.date} ${a.time[1]}`) -
              new Date(`${b.date} ${b.time[1]}`),
          )
          .map((item, index) => {
            return (
              <div className="field">
                <DateLabel>
                  {moment(item.date).format('YYYY-MM-DD ddd')}.
                </DateLabel>
                <div className="button-wrapper">
                  <TimeButton
                    onClick={() => {
                      setMessage({error: false, text: ''});
                      onSelectDurationRecord(item);
                    }}
                    selected={getSelectedDurationRecordIndex(item) >= 0}>
                    {item.time[0]} - {item.time[1]}
                  </TimeButton>
                </div>
              </div>
            );
          })}
      </section>

      {app.state.member && (
        <div className="action-bar">
          {message.text && (
            <div className="message-container">{message.text}</div>
          )}
          <div className="buttons-container">
            <Button
              type="outline"
              onClick={onCancel}
              style={{
                marginRight: 15,
              }}>
              取消
            </Button>
            <Button type="primary" onClick={onConfirm}>
              確定報名
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 30px 20px 20px;
    color: #4a4a4a;
    line-height: 22px;
  }

  & > section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .field {
      margin: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & > .action-bar {
    border-top: 1px solid #cccccc;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & > .message-container {
      margin-bottom: 10px;
    }

    & > .buttons-container {
      display: flex;
    }
  }
`;

const DateLabel = styled.div`
  margin-right: 35px;
  width: 120px;

  color: ${(props) => (props.selected ? '#EC8E25' : '#4A4A4A')};
  line-height: 22px;
  cursor: pointer;
`;

const TimeButton = styled.button`
  margin-bottom: 10px;
  border: 0px;
  border-radius: 4px;
  padding: 9px 21px;
  min-width: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.selected ? '#EC8E25' : '#E3E3E3')};
  color: ${(props) => (props.selected ? '#FFFFFF' : '#4A4A4A')};
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

export default SignupDetail;
