//reference: https://docs.google.com/spreadsheets/d/18NJwsT5hiDRk8cUTqJAF-bjK7elDtywfosr5xCOcUfI/edit#gid=360743443

export const INITIAL_FIELDS = {
  name: '',
  date_start: '',
  date_end: '',
  address_name: '',
  address: '',
  status: 'draft',
  description: '',
  content: '',
  image: '',
  duration: [
    {date: '', time: []},
    {date: '', time: []},
    {date: '', time: []},
  ],
};
