import React from 'react';
import styled from 'styled-components';
import {Button, Tab, Tabs} from 'antd';

import * as AppContext from '../../AppContext';
import {BellOutlined} from '../../Widgets/Icon';
import ActivityTable from '../../Components/Activity.Table';
import ActivityDetailDisplay from '../../Components/Activity.DisplayDetail';
import ActivityDetailForm from './ActivityDetailForm';

function ActivityMgrPage(props) {
  const app = React.useContext(AppContext.Context);
  const [records, setRecords] = React.useState(null);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [updater, setUpdater] = React.useState(0);

  const defaultRecord = {
    name: '',
    address: '',
    publish: false,
    accept: false,
    end: false,
  };

  const fetchActivityList = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let records = await app.actions.fetchActivityList();
      setRecords(records);
    } catch (err) {
      console.warn('fetch activity list error');
    }

    app.actions.setLoading(false);
  }, [app.actions]);

  const fetchActivityDetailById = React.useCallback(
    async (id) => {
      app.actions.setLoading(true);
      try {
        let record = await app.actions.fetchActivityById(id);
        setSelectedRecord(record);
      } catch (err) {
        console.warn('fetch activity detail errpr');
      }
      app.actions.setLoading(false);
    },
    [app.actions],
  );

  React.useEffect(() => {
    fetchActivityList();
  }, [fetchActivityList]);

  return (
    <Wrapper>
      <div className="row-container">
        {!selectedRecord ? (
          <div className="header">
            <h3 className="title">活動管理</h3>
            <Button
              type="primary"
              onClick={() => setSelectedRecord(defaultRecord)}>
              新增活動
            </Button>
          </div>
        ) : (
          <div className="header">
            <Button
              type="primary"
              onClick={() => {
                fetchActivityList();
                setSelectedRecord(null);
              }}>
              回管理列表
            </Button>
          </div>
        )}
      </div>

      <div className="column-container">
        {!selectedRecord ? (
          <ActivityTable
            records={records}
            goToDetail={(record) => {
              setSelectedRecord(record);
            }}
          />
        ) : (
          <Tabs type="card">
            <Tabs.TabPane tab="活動預覽" key="1">
              <ActivityDetailDisplay record={selectedRecord} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="活動編輯" key="2">
              <ActivityDetailForm
                record={selectedRecord}
                onRefresh={(id) => fetchActivityDetailById(id)}
                onCancel={() => setSelectedRecord(null)}
              />
            </Tabs.TabPane>
          </Tabs>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 40px;
  background-color: white;

  & > .row-container {
    display: flex;

    & > .header {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      margin-bottom: 20px;

      & > .title {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 22px;
      }
    }

    & > .column-container {
    }
  }
`;

export default ActivityMgrPage;
